import styled from "styled-components";

const breakpoints = {
    small_laptop: "1200px"
};

export const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;
    background-color: #fff;
`;

export const ImageContainer = styled.img`
    width: auto;
    height: 100%;
    max-width: 40%;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        display: none;
    }
`;

export const ContactUsContainer = styled.div`
    background: radial-gradient(circle, #2d343f, #000);
    border-radius: 10px;
    padding: 2rem 2rem;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    width: 40%;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        max-width: 500px;
        width: 75%;
    }
`;

export const TitleContact = styled.div`
    display: flex;
    font-family: 'POPPINS';
    color: #fff;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    font-size: 6vh;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1.8rem;
    }
`;

export const FormContainer = styled.form`
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        gap: 2rem;
        margin-top: 1.5rem;
    }
`;

export const NameContainer = styled.input`
    height: 2.5rem;
    padding-left: 0.3rem;
    border: none;
    font-family: inherit;
    border-radius: 6px;
    width: 100%;
    outline: none;
    transition: box-shadow 0.2s;
    font-size: 1rem;
    max-width: 300px;

    &:focus {
        box-shadow: 0 0 0 2px #e79235;
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        height: 2rem;
        font-size: 0.8rem;
    }
`;

export const EmailContainer = styled.input`
    height: 2.5rem;
    width: 100%;
    padding-left: 0.3rem;
    border: none;
    border-radius: 6px;
    font-family: inherit;
    outline: none;
    transition: box-shadow 0.2s;
    font-size: 1rem;
    max-width: 300px;

    &:focus {
        box-shadow: 0 0 0 2px #e79235; 
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        height: 2rem;
        font-size: 0.8rem;
    }
`;

export const MessageContainer = styled.textarea`
    height: 10rem;
    width: 100%;
    border: none;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    border-radius: 6px;
    outline: none;
    font-family: inherit;
    transition: box-shadow 0.2s;
    font-size: 1rem;
    resize: none;

    &:focus {
        box-shadow: 0 0 0 2px #e79235;
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 0.8rem;
    }
`;

export const Button = styled.button`
    padding: 10px;
    background-color: #e79235;
    min-width: 30%;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 3vh;
    font-family: "ROBOTO";
    font-weight: 700;
    box-shadow: -10px 10px 15px rgba(0, 0, 0, 1);
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: #ff7f00;
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 0.8rem;
    }
`;

export const ReplyContainer = styled.div`
    color: ${({ success }) => (success ? 'green' : 'red')};
`;
