import styled, { keyframes } from "styled-components";
import logoImage from '../../../resources/images/logo_background.png';
import { fadeInLeft, fadeInRight } from 'react-animations'

const fadeLeftAnimation = keyframes`${fadeInLeft}`;
const fadeRightAnimation = keyframes`${fadeInRight}`;

const breakpoints = {
    small_laptop: "1200px",
    large_desktop: "2200px"
};

const slide = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    overflow: hidden;
    background-color: #fff;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait){
        align-items: center;
    }
`

export const TopContainer = styled.div`
    display: flex;
    position: relative;
    background: radial-gradient(circle, #2d343f, #000);
    flex-direction: column;
    height: auto;
    width: 100%;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        align-items: center;
    }
`

export const StripContainer = styled.div`
    display: flex;
    position: relative;
    height: 100vh;
    flex-direction: column;
    width: auto;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        align-items: center;
        height: auto;
    }
`

export const AnimationVideoContainer = styled.video`
    display: flex;
    position: absolute;
    right: min(0px, calc(100vw - 48vw - 120vh));
    top: -8%;
    width: auto;
    height: 120vh;
    animation: 2s ${fadeRightAnimation};
    mix-blend-mode: lighten;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        position: static;
        min-width: 45rem;
        max-width: 65rem;
        height: auto;
        width: 90%;
        min-width: auto;
        margin-top: 7rem;
    }
`

export const LogoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 8rem;
    position: relative;
    background-image: url(${logoImage});
    background-position: bottom -5vh left -30vw;
    background-size: cover;
    height: 100vh;
    width: 60vw;
    animation: 1.5s ${fadeLeftAnimation};
    background-repeat: no-repeat;
`

export const TextItem = styled.div`
    color: white;
    font-size: 6vh;
    font-family: 'POPPINS';
    font-weight: 500;
    width: 30vw;
    margin-left: 4rem;
    line-height: 8vh;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
    animation: 2.5s ${fadeLeftAnimation}; 

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        width: 90%;
        margin: 0;
        padding: 3.5rem 0;
        font-size: 2.3rem;
        line-height: 2.4rem;
        text-align: center;
    }
`

export const Button = styled.button`
    background-color: #e79235;
    color: white;
    width: 14vw;
    margin-left: 4rem;
    margin-top: 3rem;
    font-size: 3vh;
    font-family: 'Arial';
    font-weight: 700;
    padding: 2vh 2vw;
    text-align: center;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); 
    transition: background-color 0.3s, box-shadow 0.3s;
    
    &:hover {
        cursor: pointer;
        background-color: #ff7f00; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        width: 45%;
        margin: 2rem 0;
        font-size: 1.2rem;
        height: auto;
    }
`;

export const PartnersContainer = styled.div`
    width: 100%;
    height: auto;
    background: linear-gradient(to bottom, #c2c2c2, transparent);
`;

export const PartnersTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 4vh;
    color: #00000089;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1.6rem;
    }
`

export const PartnersSlide = styled.div`
    display: inline-block;
    animation: 14s ${slide} infinite linear;
`;

export const PartnersCarousel = styled.div`
    overflow: hidden;
    padding: 6rem 0;
    white-space: nowrap;
    position: relative;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        padding: 4rem 0;
    }
`;

export const PartnerImage = styled.img`
    height: 17vh;
    margin: 0 6rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        height: 7rem;
        margin: 0 4rem;
    }
`;