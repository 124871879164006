import React from 'react';
import './App.css';
import HomePage from './Pages/HomePage';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


function App() {

  // Render the application once loading and error checks are done
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        {/* Catch-all route to redirect unknown routes to "/" */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
