import {
  PageContainer,
  ImageContainer,
  ContactUsContainer,
  TitleContact,
  NameContainer,
  EmailContainer,
  MessageContainer,
  FormContainer,
  Button,
  ReplyContainer,
} from "./style";
import React, { useRef, useState, forwardRef } from "react";
import emailjs from "@emailjs/browser";

const ContactUs = forwardRef((props, ref) => {
  const form = useRef();
  const [emailStatus, setEmailStatus] = useState(null);

  const handleFieldFocus = () => {
    setEmailStatus(null);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          form.current.reset();
          const successMessage = "Email sent successfully.";
          setEmailStatus(successMessage);
        },
        (error) => {
          form.current.reset();
          const errorMessage = "Error sending the email.";
          setEmailStatus(errorMessage);
        }
      );
  };

  return (
    <PageContainer ref={ref}>
      <ImageContainer
        src="https://ik.imagekit.io/crowdflow/tr:w-800/contact_us.png"
        srcSet="https://ik.imagekit.io/crowdflow/tr:w-400/contact_us.png 400w,
          https://ik.imagekit.io/crowdflow/tr:w-800/contact_us.png 800w,
          https://ik.imagekit.io/crowdflow/tr:w-1200/contact_us.png 1200w"
      />
      <ContactUsContainer>
        <TitleContact>Contact Us</TitleContact>
        <FormContainer ref={form} onSubmit={sendEmail}>
          <NameContainer
            required
            label="Name"
            placeholder="Name"
            name="user_name"
            type="text"
            onFocus={handleFieldFocus}
          />
          <EmailContainer
            required
            label="Email"
            placeholder="Email"
            name="user_email"
            type="email"
            onFocus={handleFieldFocus}
          />
          <MessageContainer
            required
            label="Message"
            placeholder="Message"
            name="message"
            type="text"
            onFocus={handleFieldFocus}
          />
          {emailStatus && (
            <ReplyContainer
              success={emailStatus === "Email sent successfully."}
            >
              {emailStatus}
            </ReplyContainer>
          )}
          <Button type="submit">Send</Button>
        </FormContainer>
      </ContactUsContainer>
    </PageContainer>
  );
});

export default ContactUs;
