import styled from "styled-components";
import { FaLinkedin } from 'react-icons/fa';

const breakpoints = {
    small_laptop: "1200px"
};

export const PageContainer = styled.div`
    width: 100%;
    padding-bottom: 8rem;
    background-color: #fff;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        padding-bottom: 5rem;
    }
`

export const Title = styled.div`    
    color: #333;
    font-family: 'POPPINS';
    font-weight: 500;
    font-size: 6vh;
    padding-left: 6rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 2rem;
        padding: 1rem 2.4rem;
        text-align: center;
    }
`

export const Subtitle = styled.div`
    padding: 2rem 6rem;
    color: #515151;
    font-weight: 400;
    font-size: 4vh;
    font-family: 'ROBOTO';

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
        line-height: normal;
        padding: 0 1rem;
    }
`

export const StripContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: radial-gradient(circle, #2d343f, #000);
    padding: 1rem 0;
    margin: 1rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
`

export const ImagesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem 0;
`

export const Image = styled.img`
    width: 100%;
    height: auto;
    max-width: 200px;
    border-radius: 10px;
    transition: filter 0.3s ease, transform 0.3s ease;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        max-width: 150px;
    }
`

export const LinkedInIcon = styled(FaLinkedin)`
    position: absolute;
    margin-top: 15rem;
    font-size: 2rem;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        margin-top: 11rem;
    }
`;

export const Name = styled.div`
    font-size: 3vh;
    font-weight: 500;
    margin-top: 1rem;
    color: #fff;
    transition: color 0.3s ease;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
    }
`

export const ImageNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 0;

    &:hover ${LinkedInIcon} {
        opacity: 1;
        color: #fff;

        &:hover {
            color: #0077b5;
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    &:hover ${Name} {
        color: #e79235;
    }

    &:hover ${Image} {
        filter: brightness(0.6);
        transform: scale(1.05);
    }
`

export const BottomText = styled.div`
    padding: 1rem 6rem;
    color: #fff;
    font-weight: 400;
    font-size: 3vh;
    font-family: 'ROBOTO';
    text-align: center;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
        line-height: normal;
        padding: 0 1rem;
    }
`