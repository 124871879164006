import React, { useState, forwardRef } from "react";
import {
  PageContainer,
  TitleContainer,
  ButtonsContainer,
  Button,
  ContentContainer,
  ImageContainer,
  TextContainer,
  TextTitle,
  TextContent,
} from "./style";

// Página de introdução ao WebSite
const StrategyInfo = forwardRef((props, ref) => {
  const [selectedButton, setSelectedButton] = useState("Layout Optimization");

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
  };

  const strategyData = {
    "Layout Optimization": {
      image: "layout_optimization",
      text: "Gain invaluable insights to guide your layout adjustments effectively. Whether you're tweaking the placement of elements or refining the overall design, our platform provides the data-driven guidance you need to make informed decisions that drive results.",
    },
    "Product Placement": {
      image: "product_placement",
      text: "CrowdFlow gives you the power to elevate your product placement strategies like never before. Our platform empowers you to fine-tune your product placement techniques, ensuring maximum exposure and resonance with your target audience.",
    },
    "Queue Management": {
      image: "queue_management",
      text: "With CrowdFlow, you gain the tools to optimize your queue management strategies effortlessly. Our platform enables you to streamline queues, enhancing efficiency and improving customer satisfaction.",
    },
    "Marketing Optimization": {
      image: "marketing_optimization",
      text: "Unlock the potential to refine your marketing strategies to unprecedented levels. Our platform empowers you to tailor your marketing efforts, maximizing impact and engagement with your audience.",
    },
    "Resource Allocation": {
      image: "resource_allocation",
      text: "Our cutting-edge platform revolutionizes the way you pinpoint and allocate resources, offering unparalleled efficiency and precision. From workforce deployment to inventory management, our platform provides the tools you need to streamline operations and maximize productivity.",
    },
  };

  const generateSrc = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-800/${imageName}.png`;

  const generateSrcSet = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-400/${imageName}.png 400w,
    https://ik.imagekit.io/crowdflow/tr:w-800/${imageName}.png 800w,
    https://ik.imagekit.io/crowdflow/tr:w-1200/${imageName}.png 1200w`;

  return (
    <PageContainer ref={ref}>
      <TitleContainer>Empower Your Strategy</TitleContainer>
      <ButtonsContainer>
        {Object.keys(strategyData).map((buttonText) => (
          <Button
            key={buttonText}
            onClick={() => handleButtonClick(buttonText)}
            isSelected={selectedButton === buttonText}
          >
            {buttonText}
          </Button>
        ))}
      </ButtonsContainer>
      <ContentContainer>
        <ImageContainer
          loading="lazy"
          src={generateSrc(strategyData[selectedButton].image)}
          srcSet={generateSrcSet(strategyData[selectedButton].image)}
        />
        <TextContainer>
          <TextTitle>{selectedButton}</TextTitle>
          <TextContent>{strategyData[selectedButton].text}</TextContent>
        </TextContainer>
      </ContentContainer>
    </PageContainer>
  );
});

export default StrategyInfo;
