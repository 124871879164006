import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";

const breakpoints = {
  small_laptop: "1200px",
};

// Componente respetivo à NavBar toda
export const Header = styled.div`
  position: absolute;
  width: 100%;
  padding-top: 1rem;
  height: auto;
  display: flex;
  z-index: 1;
  background-color: red;
  background: linear-gradient(to bottom, #020303, transparent);

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    align-items: flex-start;
    justify-content: space-between;
    height: 5rem;
  }
`;

// Componente respetivo às diferentes opções About us etc.
export const NavItems = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const NavItem = styled.div`
  color: white;
  white-space: nowrap;
  font-size: 3vh;
  font-family: "Arial";
  transition: color 0.3s ease;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    padding-top: 0;
    font-size: 1.2rem;
  }

  &:hover {
    color: #e79235;
    cursor: pointer;
  }
`;

export const LogoImg = styled.img`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 10vh;
  align-items: flex-end;
  transition: transform 0.3s ease;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    padding-top: 0;
    height: auto;
    width: 70%;
    max-width: 15rem;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

export const MenuIcon = styled(FaBars)`
  font-size: 2.3rem;
  color: #fff;
  transition: color 0.3s ease;
  margin: 1.3rem 1.5rem 0 0;

  &:hover {
    color: #727272;
    cursor: pointer;
  }
`;

export const MenuContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.98);
  position: absolute;
  width: 80vw;
  height: 100vh;
  top: ${props => props.scrollY}px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
`;

export const CloseIcon = styled(IoCloseOutline)`
  font-size: 1.8em;
  color: #fff;
  transition: color 0.3s ease;
  margin: 1.3rem 0 0 0;

  &:hover {
    color: #e79235;
    cursor: pointer;
  }
`;
