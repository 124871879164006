import React from "react";
import { PageContainer, VideoContainer, TitleContainer } from "./style";

const AllInOnePlatform = () => {
  return (
    <>
      <PageContainer>
      <VideoContainer autoPlay loop muted playsInline>
          <source src="https://ik.imagekit.io/crowdflow/tr:w-800/pc_video.mp4" srcSet="https://ik.imagekit.io/crowdflow/tr:w-400/pc_video.mp4 400w,
          https://ik.imagekit.io/crowdflow/tr:w-800/pc_video.mp4 800w,
          https://ik.imagekit.io/crowdflow/tr:w-1200/pc_video.mp4 1200w" type="video/mp4" />
          <source src="https://ik.imagekit.io/crowdflow/tr:w-800/pc_video.webm" srcSet="https://ik.imagekit.io/crowdflow/tr:w-400/pc_video.webm 400w,
          https://ik.imagekit.io/crowdflow/tr:w-800/pc_video.webm 800w,
          https://ik.imagekit.io/crowdflow/tr:w-1200/pc_video.webm 1200w" type="video/webm" />
          Your browser does not support the video tag.
        </VideoContainer>
        <TitleContainer>All in one platform</TitleContainer>
      </PageContainer>
    </>
  );
};

export default AllInOnePlatform;
