import styled from "styled-components";


const breakpoints = {
    small_laptop: "1200px"
};

export const PageContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding-bottom: 4rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        padding-bottom: 3rem;
    }
`

export const TitleContainer = styled.div`
    color: #333;
    font-family: 'POPPINS';
    font-weight: 500;
    font-size: 6vh;
    padding-left: 6rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 2rem;
        padding: 0rem 0.4rem;
        text-align: center;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    padding: 2rem 6rem 1rem 6rem;
    gap: 2rem;
    
    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        flex-wrap: nowrap;
        justify-content: normal;
        overflow: auto;
        padding: 1rem 4rem 1rem 1rem;
        gap: 5rem;
        
    }
`
export const Button = styled.div`
    display: flex;
    position: relative;
    font-family: 'POPPINS';
    align-items: flex-end;
    text-align: start;
    line-height: 4vh;
    white-space: nowrap;
    padding-bottom: 0.2rem;
    font-size: 3vh;
    color: #333;
    transition: color 0.3s ease;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 60%;
        height: 4px;
        background-color: ${({ isSelected }) => isSelected ? '#ff9845' : '#9b9b9b'};
        border-radius: 2px;
        transition: background-color 0.3s ease;
    }
    
    &:hover {
        color: #727272;
        cursor: pointer;
    } 

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    height: auto; 
    padding-top: 1rem;
    gap: 2rem;
    align-items: center;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        flex-direction: column;
        gap: 0rem;
    }    
`

export const ImageContainer = styled.img`
    display: flex;
    width: 50%;
    max-height: 30rem;
    object-fit: contain;
    transition: transform 0.3s ease;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        width: 100%;
        max-height: 16rem;
    }

    &:hover {
        transform: scale(1.02);
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        width: auto;
        padding: 0 1rem;
    }
`

export const TextTitle = styled.div`
    display: flex;
    position: relative;
    font-family: 'POPPINS';
    color: #333;
    font-weight: 400;
    line-height: 6vh;
    font-size: 5vh;
    white-space: nowrap;
    width: 11rem;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-color: #ff9845;
        border-radius: 2px;
        transition: background-color 0.3s ease;
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 2rem;
        margin-top: 2rem;
        line-height: 2.5rem;
        width: 7rem;
    }
`

export const TextContent = styled.div`
    font-family: 'Roboto';
    color: #515151;
    font-weight: 400;
    font-size: 4vh;
    padding: 2rem 2rem 0 0;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
    }
`