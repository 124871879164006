import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Header,
  NavItems,
  NavItem,
  LogoImg,
  MenuIcon,
  MenuContainer,
  CloseIcon,
} from "./style";

// Componente Navbar

const NavBar = ({
  scrollToValuableInsights,
  scrollToAboutUs,
  scrollToContactUs,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [scrollY, setScrollY] = useState(0);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    const handleScroll = () => {
      setMenuOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Navegar para a homepage ao clicar no logo
  const handleClickLogo = () => {
    navigate("/");
  };

  // Chamar a funcoes de scroll ao clicar nos botoes
  const handleClickServices = () => {
    scrollToValuableInsights();
  };

  const handleClickAboutUs = () => {
    scrollToAboutUs();
  };

  const handleClickContactUs = () => {
    scrollToContactUs();
  };

  return (
    <Header>
      <LogoImg
        loading="lazy"
        fetchPriority="high"
        src="https://ik.imagekit.io/crowdflow/tr:w-400/logo_crowdflow_white.png"
        srcSet="https://ik.imagekit.io/crowdflow/tr:w-200/logo_crowdflow_white.png 800w,
        https://ik.imagekit.io/crowdflow/tr:w-400/logo_crowdflow_white.png 1200w"
        onClick={handleClickLogo}
      />

      {windowWidth <= 1200 || windowHeight > windowWidth ? (
        <>
          {!menuOpen ? (
            <MenuIcon onClick={toggleMenu} />
          ) : (
            <MenuContainer ref={menuRef} scrollY={scrollY}>
              <CloseIcon onClick={toggleMenu} />
              <NavItem onClick={handleClickServices}>Services</NavItem>
              <NavItem onClick={handleClickAboutUs}>About us</NavItem>
              <NavItem onClick={handleClickContactUs}>Contacts</NavItem>
            </MenuContainer>
          )}
        </>
      ) : (
        <>
          <NavItems>
            <NavItem onClick={handleClickServices}>Services</NavItem>
            <NavItem onClick={handleClickAboutUs}>About us</NavItem>
            <NavItem onClick={handleClickContactUs}>Contacts</NavItem>
          </NavItems>
        </>
      )}
    </Header>
  );
};

export default NavBar;
