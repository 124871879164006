import styled from "styled-components";
import { FaLinkedin, FaInstagram } from "react-icons/fa";

const breakpoints = {
  small_laptop: "1200px",
};

export const PageContainer = styled.div`
  margin-top: -2px;
  background: radial-gradient(circle, #2d343f, #000);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: space-around;
  gap: 2rem;
  padding-bottom: 3rem;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
`;

export const Logo = styled.img`
  display: flex;
  object-fit: contain;
  height: 15vh;
  align-self: start;
  transition: transform 0.3s ease;

  @media (max-width: ${breakpoints.small_laptop}),(orientation: portrait) {
    align-self: auto;
    height: auto;
    width: 70%;
    max-width: 15rem;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 2rem;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    padding-top: 0;
    gap: 1rem;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-family: "POPPINS";
  font-weight: 400;
  color: #9d9d9d;
  font-size: 4vh;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    font-size: 1.8rem;
  }
`;

export const Item = styled.div`
  width: auto;
  font-family: "POPPINS";
  font-weight: 400;
  color: #fff;
  font-size: 3vh;
  transition: color 0.3s ease;

  &:hover {
    color: #e79235;
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    font-size: 1.6rem;
  }
`;

export const SocialsContainer = styled.div`
  width: auto;
  padding-top: 2rem;
  margin-right: 15%;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    padding-top: 0;
    margin-right: 0;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 4rem;
  padding-top: 2rem;
  gap: 1rem;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    justify-content: center;
  }
`;

export const InstagramIcon = styled(FaInstagram)`
  font-size: 4vh;
  color: #fff;
  transition: color 0.3s ease;

  &:hover {
    color: #e1306c;
    cursor: pointer;
    scale: 1.03;
  }

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    font-size: 2rem;
  }
`;

export const LinkedinIcon = styled(FaLinkedin)`
  font-size: 4vh;
  color: #fff;
  transition: color 0.3s ease;

  &:hover {
    color: #0077b5;
    cursor: pointer;
    scale: 1.03;
  }

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    font-size: 2rem;
  }
`;

export const CopyrightFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 1rem;
  align-items: center;
`;

export const CopyrightText = styled.div`
  font-size: 2vh;
  color: #9d9d9d;
  font-family: "ARIAL";
  font-weight: 400;
  text-align: center;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    font-size: 1.2rem;
  }
`;


export const AttributionContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    font-size: 1.5vh;
    color: #555;
    opacity: 0; /* Definindo opacidade inicial */
    transition: opacity 0.5s ease-in-out; /* Definindo a transição de opacidade */

    /* Aplicando opacidade quando o nome do autor da imagem muda */
    &:not(:empty) {
        opacity: 1;
    }

    @media (max-width: ${breakpoints.small_laptop}) or (orientation: portrait) {
      font-size: 1rem;
    }
`;