import styled, { css, keyframes } from 'styled-components';

const breakpoints = {
    small_laptop: "1200px"
  };


const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    filter: blur(3px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
`;

const animatedStyle = css`
  opacity: 0;
  ${({ inView }) => inView && css`
    animation: ${slideInFromLeft} 1s forwards;
  `}

  // Aplicar delays diferentes para cada nth-child
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    &:nth-child(2) {
      animation-delay: 0s;
    }
    &:nth-child(3) {
      animation-delay: 0s;
    }
    }
`;

export const PageContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding-bottom: 6rem;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        padding-bottom: 3rem;
    }
`

export const StripContainer = styled.div`
    display: flex;
    background: radial-gradient(circle, #2d343f, #000);
    flex-direction: column;
    width: 100%;
`

export const Title = styled.div`
    width: 100%;
    text-align: center;
    font-family: 'POPPINS';
    font-weight: 500;
    font-size: 6vh;
    color: #fff;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 2rem;
    }
`

export const CardsContainer = styled.div`
    width: 100%;
    padding-top: 4rem;
    flex-direction: row;
    display: flex;
    justify-content: space-around;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        display: grid;
        justify-items: center;
        padding-top: 2rem;
        gap: 2rem;
    }
`

export const Card = styled.div`
    width: 28%;
    max-width: 32rem;
    height: auto;
    background-color: white;
    padding-bottom: 2rem;
    border-radius: 33px;
    background: url(${props => props.backgroundImage});
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
    ${animatedStyle}

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        width: 80%;
        padding-bottom: 1rem;
    }
`

export const CardTitle = styled.div`
    position: relative;
    width: 32%;
    margin-top: 15rem;
    font-weight: 400;
    font-family: 'POPPINS';
    color: #333;
    font-size: 5vh;
    margin-left: 2rem;
    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-color: #ff9845;
        border-radius: 2px;
        transition: background-color 0.3s ease;
    }

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1.5rem;
        width: 15%;
        margin-left: 1rem;
    }
`

export const CardText = styled.div`
    width: calc(100%-3rem);
    font-family: 'ROBOTO';
    font-weight: 400;
    font-size: 3vh;
    margin: 0 2rem;
    padding-top: 1rem;
    color: #515151;

    @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
        font-size: 1rem;
        margin: 0 1rem;
    }
`