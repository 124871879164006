import React, { useState, forwardRef } from "react";
import {
  PageContainer,
  TitleContainer,
  ButtonsContainer,
  Button,
  ContentContainer,
  ImageContainer,
  TextContainer,
  TextTitle,
  TextContent,
} from "./style";

const ValuableInsights = forwardRef((props, ref) => {
  const [selectedButton, setSelectedButton] = useState("Heatmap");

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
  };

  const insightsData = {
    Heatmap: {
      text:
        "Gain deep understanding with our heatmap analysis - where analytics tells you what's happening, heatmaps reveal why it's happening. Illuminate the path to optimization by uncovering user behaviors and patterns, empowering you to make data-driven decisions that resonate and drive meaningful results.",
      image: "heatmap",
    },
    Flowmap: {
      text:
        "Flowmaps illuminate the journey users take through your platform, revealing the why behind their actions. Harness this visual storytelling tool to optimize pathways, enhance user experience, and drive conversions with precision and clarity.",
      image: "flowmap",
    },
    Demographics: {
      text:
        "Demographics analysis. Beyond mere numbers, uncover the rich tapestry of who comprises your user base. From age and gender to location and interests, gain profound insights into the unique demographics shaping your platform's engagement.",
      image: "demographics",
    },
    "Waiting Time": {
      text:
        "Minimize frustration and maximize satisfaction. Understand the critical moments ensuring efficient processes and enhancing overall experience. By pinpointing bottlenecks you can reduce wait times that keeps customers coming back for more.",
      image: "wait_time",
    },
    "People Count": {
      text:
        "Harness the power of precise people count analysis to understand foot traffic dynamics like never before. With real-time insights at your fingertips, you can adapt strategies dynamically, ensuring seamless navigation, improved safety measures, and ultimately, greater satisfaction for your visitors.",
      image: "people_count",
    },
  };

  const generateSrc = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-800/${imageName}.png`;

  const generateSrcSet = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-400/${imageName}.png 400w,
    https://ik.imagekit.io/crowdflow/tr:w-800/${imageName}.png 800w,
    https://ik.imagekit.io/crowdflow/tr:w-1200/${imageName}.png 1200w`;

  return (
    <PageContainer ref={ref}>
      <TitleContainer>Valuable Insights</TitleContainer>
      <ButtonsContainer>
      {Object.keys(insightsData).map((buttonText) => (
          <Button
            key={buttonText}
            onClick={() => handleButtonClick(buttonText)}
            isSelected={selectedButton === buttonText}
          >
            {buttonText}
          </Button>
        ))}
      </ButtonsContainer>
      <ContentContainer>
      <ImageContainer
          loading="lazy"
          src={generateSrc(insightsData[selectedButton].image)}
          srcSet={generateSrcSet(insightsData[selectedButton].image)}
        />
        <TextContainer>
          <TextTitle>{selectedButton}</TextTitle>
          <TextContent>{insightsData[selectedButton].text}</TextContent>
        </TextContainer>
      </ContentContainer>
    </PageContainer>
  );
});

export default ValuableInsights;
