import styled from "styled-components";

// Define breakpoints for different screen sizes
const breakpoints = {
  small_laptop: "1200px"
};

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: #fff;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
  }
`;

export const VideoContainer = styled.video`
  width: 50%;
  height: auto;
  margin: 0 2rem;

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    width: 90%;
    height: auto;
    margin: 0;
  }
`;

export const TitleContainer = styled.div`
  width: 50%;
  color: #333;
  font-size: 13vh;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-content: center;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);

  @media (max-width: ${breakpoints.small_laptop}), (orientation: portrait) {
    width: 100%;
    font-size: 3rem;
  }
`;
