import React, { useEffect, useState } from "react";
import {
  PageContainer,
  TopContainer,
  StripContainer,
  AnimationVideoContainer,
  LogoImageContainer,
  TextItem,
  Button,
  PartnersContainer,
  PartnersTitle,
  PartnersCarousel,
  PartnersSlide,
  PartnerImage,
} from "./style";

// Página de introdução ao WebSite

const Intro = ({ scrollToStrategyInfo }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Chamar a funcao para dar scroll ao clicar no learn more
  const handleClickLearnMore = () => {
    scrollToStrategyInfo();
  };

  const partners = [
    { id: 1, image: "startup_braga" },
    { id: 2, image: "start_point" },
    { id: 3, image: "liftoff" },
    { id: 4, image: "uminho" },
    { id: 5, image: "microsoft" },
  ];

  const generatePartnerImageSrc = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-400/${imageName}.png`;

  const generatePartnerImageSrcSet = (imageName) =>
    `https://ik.imagekit.io/crowdflow/tr:w-200/${imageName}.png 400w, https://ik.imagekit.io/crowdflow/tr:w-400/${imageName}.png 1200w`;

  return (
    <PageContainer>
      <TopContainer>
        <StripContainer>
        <AnimationVideoContainer autoPlay loop muted playsInline>
          <source src="https://ik.imagekit.io/crowdflow/tr:w-800/vid.mp4" srcSet="https://ik.imagekit.io/crowdflow/tr:w-400/vid.mp4 400w,
          https://ik.imagekit.io/crowdflow/tr:w-800/vid.mp4 800w,
          https://ik.imagekit.io/crowdflow/tr:w-1200/vid.mp4 1200w" type="video/mp4" />
          <source src="https://ik.imagekit.io/crowdflow/tr:w-800/vid.webm" srcSet="https://ik.imagekit.io/crowdflow/tr:w-400/vid.webm 400w,
          https://ik.imagekit.io/crowdflow/tr:w-800/vid.webm 800w,
          https://ik.imagekit.io/crowdflow/tr:w-1200/vid.webm 1200w" type="video/webm" />
          Your browser does not support the video tag.
        </AnimationVideoContainer>
          {(windowWidth > 1200 && windowHeight < windowWidth) ? (
            <LogoImageContainer>
              <TextItem>
                Enhance Costumer Experience And Maximize Your Revenue
              </TextItem>
              <Button onClick={handleClickLearnMore}>Learn More</Button>
            </LogoImageContainer>
          ) : (
            <>
              <TextItem>
                Enhance Costumer Experience And Maximize Your Revenue
              </TextItem>
              <Button onClick={handleClickLearnMore}>Learn More</Button>
            </>
          )}
        </StripContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 150 1440 150">
          <path
            fill="#c2c2c2"
            fillOpacity="1"
            d="M1440,224L1360,229.3C1280,235,1120,245,960,234.7C800,224,640,192,480,181.3C320,171,160,181,80,186.7L0,192L0,320L80,320C160,320,320,320,480,320C640,320,800,320,960,320C1120,320,1280,320,1360,320L1440,320Z"
          ></path>
        </svg>
      </TopContainer>
      <PartnersContainer>
        <PartnersTitle>Our Partners</PartnersTitle>
        <PartnersCarousel>
          <PartnersSlide>
            {partners.map((partner) => (
                <PartnerImage
                  key={partner.id}
                  loading="lazy"
                  src={generatePartnerImageSrc(partner.image)}
                  srcSet={generatePartnerImageSrcSet(partner.image)}
                />
            ))}
          </PartnersSlide>
          <PartnersSlide>
            {partners.map((partner) => (
                <PartnerImage
                  key={partner.id + partners.length}
                  loading="lazy"
                  src={generatePartnerImageSrc(partner.image)}
                  srcSet={generatePartnerImageSrcSet(partner.image)}
                />
            ))}
          </PartnersSlide>
        </PartnersCarousel>
      </PartnersContainer>
    </PageContainer>
  );
};

export default Intro;
