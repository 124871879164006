import React, { useState, useEffect } from "react";
import {
  PageContainer,
  ContentWrapper,
  Logo,
  SupportContainer,
  Title,
  Item,
  SocialsContainer,
  IconsContainer,
  LinkedinIcon,
  InstagramIcon,
  CopyrightFooter,
  CopyrightText,
  AttributionContainer,
} from "./style";

const Footer = ({
  scrollToValuableInsights,
  scrollToAboutUs,
  scrollToContactUs,
}) => {
  const attributionNames = ["jcomp", "vectorjuice", "pch.vector", "storyset", "macrovector_official", "Freepik"];
  const [currentAttributionNameIndex, setCurrentAttributionNameIndex] =
    useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAttributionNameIndex(
        (prevIndex) => (prevIndex + 1) % attributionNames.length
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [attributionNames.length]);

  // Dar redirect para as redes sociais
  const openInstagram = () => {
    window.open("https://www.instagram.com/crowdflow.ai/", "_blank");
  };

  const openLinkedin = () => {
    window.open("https://www.linkedin.com/company/crowdflow-ai", "_blank");
  };

  // Chamar a funcoes de scroll ao clicar nos botoes
  const handleClickServices = () => {
    scrollToValuableInsights();
  };

  const handleClickAboutUs = () => {
    scrollToAboutUs();
  };

  const handleClickContactUs = () => {
    scrollToContactUs();
  };

  return (
    <PageContainer>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 80 1440 180">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,160L80,170.7C160,181,320,203,480,186.7C640,171,800,117,960,101.3C1120,85,1280,107,1360,117.3L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
      <ContentWrapper>
        <Logo
          loading="lazy"
          src="https://ik.imagekit.io/crowdflow/tr:w-400/logo_crowdflow_white.png"
          srcSet="https://ik.imagekit.io/crowdflow/tr:w-300/logo_crowdflow_white.png 800w,
            https://ik.imagekit.io/crowdflow/tr:w-400/logo_crowdflow_white.png 1200w"
        />
        <SupportContainer>
          <Title>Support</Title>
          <Item onClick={handleClickServices}>Services</Item>
          <Item onClick={handleClickAboutUs}>About us</Item>
          <Item onClick={handleClickContactUs}>Contacts</Item>
        </SupportContainer>
        <SocialsContainer>
          <Title>Follow us</Title>
          <IconsContainer>
            <LinkedinIcon onClick={openLinkedin} />
            <InstagramIcon onClick={openInstagram} />
          </IconsContainer>
        </SocialsContainer>
      </ContentWrapper>
      <AttributionContainer>
        <p>
          Images taken from Freepik. Created by{" "}
          {attributionNames[currentAttributionNameIndex]}.
        </p>
      </AttributionContainer>
      <CopyrightFooter>
        <CopyrightText>© Copyright. All rights reserved.</CopyrightText>
      </CopyrightFooter>
    </PageContainer>
  );
};

export default Footer;
