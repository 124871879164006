import React, { useRef } from "react";
import WhyCrowdflow from "../Components/HomePage/WhyCrowdflow";
import Footer from "../Components/HomePage/Footer";
import NavBar from "../Components/HomePage/NavBar";
import StrategyInfo from "../Components/HomePage/StrategyInfo";
import ValuableInsights from "../Components/HomePage/ValuableInsights";
import Intro from "../Components/HomePage/Intro";
import AllInOnePlatform from "../Components/HomePage/AllInOnePlatform";
import ContactUs from "../Components/HomePage/ContactUs";
import AboutUs from "../Components/HomePage/AboutUs";

const HomePage = () => {
  // Cria uma referencias para o componentes
  const valuableInsightsRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);
  const strategyInfoRef = useRef(null);

  // Funcoes para tratar dos scrolls
  const scrollToValuableInsights = () => {
    valuableInsightsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToAboutUs = () => {
    aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContactUs = () => {
    contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStrategyInfo = () => {
    strategyInfoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <NavBar
        scrollToValuableInsights={scrollToValuableInsights}
        scrollToAboutUs={scrollToAboutUs}
        scrollToContactUs={scrollToContactUs}
      />
      <Intro scrollToStrategyInfo={scrollToStrategyInfo}/>
      <StrategyInfo ref={strategyInfoRef}/>
      <WhyCrowdflow/>
      <ValuableInsights ref={valuableInsightsRef}/>
      <AllInOnePlatform/>
      <AboutUs ref={aboutUsRef}/>
      <ContactUs ref={contactUsRef}/>
      <Footer
        scrollToValuableInsights={scrollToValuableInsights}
        scrollToAboutUs={scrollToAboutUs}
        scrollToContactUs={scrollToContactUs}
      />
    </>
  );
};

export default HomePage;